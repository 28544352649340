import { DAY_NAMES, MONTH_NAMES } from "../constants/dateConsts";

export function getMonthName(date) {
  if (date) return MONTH_NAMES[date.getMonth()];
}

export function getDayName(date) {
  if (date) return DAY_NAMES[date.getDay()];
}


export const mapPartsArray = (items) => {

  return items.map((item) => ({
    id: item.elevatorPartId,
    description: item.description,
    buildingResponsibility: false,
    item: item.item
  }));
};


//TODO: change id to violationId on backend when returning the resource graph
export const mapViolationsArray = (items) => {
  return items.map((item) => ({
    id: item.violationId,
    description: `${item.description}`,
    item: item.item
  }));
};

export const mapRemediesArray = (items) => {
  return items.map((item) => ({
    id: item.remedyId,
    description: `${item.description}`,
    item: item.item
  }));
};

export const mapManufacturers = (items) => {
  return items.map((item) => ({
    id: item.id,
    description: `${item.name}`
  }));
};

export const mapElevatorContacts = (items) => {

  return items.map((item) => ({
    id: item.contactId,
    license: item.license,
    fullName: item.fullName,
    description: `${item.fullName} - ${item.license}`
  }));
};