import { Badge, Stack } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./_SchedulerLine.css";
import Label from "../../../shared/components/label/_Label";
import IconButton from "../../../shared/components/buttons/_IconButton";
import moment from "moment/moment";

function SchedulerLine({ inspectionLine, onCardSelect }) {
  let {
    inspectionLineId,
    inspectionDate,
    inspectionTime,
    customerName,
    mobileStatus,
    isQce,
    inspLineComplete,
    inspectionType,
    buildingAddress, buildingCity, buildingState, buildingZip, borough, latitude, longitude,
    deviceName,


  } = inspectionLine;

  const onCardClick = () => {
    if (onCardSelect) onCardSelect(inspectionLine);
  };

  return (
    <Card className="scheduler-inspection-line-card">
      <Card.Body>
        <div className="px-2">
          <Stack direction="horizontal" className="justify-content-between">
            <Card.Title>
              <Label text={moment(inspectionTime, "HH:mm").format("h:mm A")} />
            </Card.Title>
            <Card.Title>
              <Badge bg="secondary">
                <Label text={inspectionType} />
              </Badge>
            </Card.Title>
            <Card.Title>
              <Label text={deviceName} />
            </Card.Title>
          </Stack>
          <Stack direction="horizontal">
            <Label
              className="flex-fill text-start"
              text={`${buildingAddress}, ${buildingCity}`}
            />
            {/* <Card.Text className="flex-fill text-start">
              
            </Card.Text> */}
            <IconButton
              title={"Open inspection"}
              iconClass={"ri-arrow-right-s-line"}
              onClick={onCardClick}
              size={"md"}
            />
          </Stack>
        </div>
      </Card.Body>
    </Card>
  );
}

export default SchedulerLine;
