import React from "react";

// components
import { Col, Container, Row, Stack, Button } from "react-bootstrap";
import Header from "../../shared/components/header/_Header.jsx";
import ResourceItem from "../../shared/components/resource-item/_ResourceItem.jsx";

//services
import { INSPECTOR_COMPONENTS, INSPECTOR_CONST } from "./_InspectorConst.js";
import Label from "../../shared/components/label/_Label.jsx";
import LargeButton from "../../shared/components/buttons/_LargeButton.jsx";
import TextInput from "../../shared/components/input/_TextInput.jsx";

const InspectorMain = ({ state, onShowComponent, onBack, elevatorContacts, onSelect }) => {


  // const onUpdateClick = () => { };

  const onSelectInspector = () => {

    onShowComponent(INSPECTOR_COMPONENTS.INSPECTOR);
  };

  // const onInspectorFullNameChanged = (value) => {
  //   setState((prev) => ({
  //     ...prev,
  //     fullName: value,
  //   }));
  // };

  // const onInspectorLicenseChanged = (value) => {
  //   setState((prev) => ({
  //     ...prev,
  //     license: value,
  //   }));
  // };

  const onSelectClick = (value) => {

    //! bubble up selection to parent
    if (onSelect) onSelect(value);
  };

  const buildLines = () => {
    return elevatorContacts.map((res) => {
      console.log(res);
      // check if we have an 'item' - if we don't - don't show the res.item, just the res.description
      return (
        <div className="border-bottom border-1 p-1" key={res.id}>
          <h3 onClick={() => onSelectClick(res)} value={res.id}>
            {res.id === state.elevatorContactId && <i className='ri-check-fill text-info'></i>} {res.item === undefined ? (`${res.description}`) : (`${res.description}`)}
          </h3>
        </div>
      );
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Elevator Inspector"}
            subTitle={"Select an inspector below"}
            onBack={onBack}
          />
        </Col>
      </Row>



      <Row>
        <Col>
          <Label text={`Selected Inspector:`} /><h2>{state.fullName ?? "Select an inspector"}</h2><br />
          <Label text={`Selected License:`} /><h2>{state.license ?? ""}</h2>


          {/* <Stack gap={3}> */}


          {/* <ResourceItem
              title={INSPECTOR_CONST.SELECT_INSPECTOR_TITLE}
              description={INSPECTOR_CONST.SELECT_INSPECTOR_DESCRIPTION}
              action={INSPECTOR_CONST.SELECT_INSPECTOR_TITLE}
              onClick={onSelectInspector}
            /> */}

          {/* <TextInput
              forKey={"inspector.fullName"}
              label={"Full Name"}
              onValueChanged={onInspectorFullNameChanged}
            />
            <TextInput
              forKey={"inspector.license"}
              label={"License"}
              onValueChanged={onInspectorLicenseChanged}
            />
            <LargeButton title="Update" onClick={onUpdateClick} /> */}
          {/* </Stack> */}

        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h1>Select Inspector</h1>
          <Stack className="p-2">{buildLines()}</Stack>
        </Col>
      </Row>


    </Container>
  );
};

export default InspectorMain;
