import React from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../../shared/components/header/_Header";
import DeficiencyDetailLine from "./_DeficiencyDetailLine";
import Label from "../../../shared/components/label/_LabelGroup";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./_DeficiencyDetailComponents";

const DeficiencyDetailMain = ({ state, onShowComponent, onBack }) => {

  const onSelectPart = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.PART);
  };

  const onSelectRemedy = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.REMEDY);
  };

  const onSelectViolation = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.VIOLATION);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Selected Deficiencies"}
            subTitle={"Click the edit icon to make changes"}
            onBack={onBack}
          />

        </Col>
      </Row>
      <Row>
        <Col>
          <Stack gap={3} className="mt-2">
            <DeficiencyDetailLine
              name={"Parts"}
              text={"SELECTED ELEVATOR PART"}
              onSelect={onSelectPart}
            />
            <Label text={`Part: ${state.part?.item}`} textClass={"px-2"} />
            <Label text={`Location: ${state.part?.description}`} textClass={"px-2"} />


            <DeficiencyDetailLine
              name={"Violations"}
              text={"SELECTED VIOLATING CONDITION"}
              onSelect={onSelectViolation}

            />
            <Label text={`Violation:  ${state.violation?.item} - ${state.violation?.description}`} textClass={"px-2"} />


            <DeficiencyDetailLine
              name={"Remedies"}
              text={"SELECTED REMEDY"}
              onSelect={onSelectRemedy}
            />
            <Label text={`Remedy: ${state.remedy?.item} - ${state.remedy?.description}`} textClass={"px-2"} />


          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default DeficiencyDetailMain;
