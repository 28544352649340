import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
    firstName: '',
    lastName: '',
    email: '',

    selectedDay: {
        date: moment(new Date()).format('M-D-YYYY'),
        dayOfWeek: moment(new Date()).format('dddd'),
    }
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setProfile: (state) => {
            state.firstName = 'Mike';
        },
        clearProfile: (state) => {
            state.firstName = 'Reset'
        },
        setSelectedDay: (state, action) => {
            state.selectedDay = action.payload;
        }
    }
});

export const { setProfile, clearProfile, setSelectedDay } = profileSlice.actions;

export default profileSlice.reducer;
