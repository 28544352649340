import React from "react";
import { Button } from "react-bootstrap";

const IconButton = ({ title, iconClass, size, onClick, className }) => {
  const getSizeClassName = () => {
    switch (size) {
        case "sm":
            return "display-7";
        case "md":
            return "display-6";
        default:
            return "display-3";
    }
  };

  return (
      <Button
        className={`m-0 ${className ?? ''}`}
        variant="link"
        onClick={onClick}
        title={title}
      >
        <i className={`${iconClass} ${getSizeClassName()}`}></i>
      </Button>
  );
};

export default IconButton;
