import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSPECTION_ROUTES_PARAMS, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useSelector } from 'react-redux';
import moment from "moment/moment";

// components
import Header from "../shared/components/header/_Header";
import InspectionDetailsBar from "./components/inspectionDetailsBar/_InspectionDetailsBar";
import ToggleButtonGroup from "../shared/components/button-group/_ToggleButtonGroup";
import ResourceItem from "../shared/components/resource-item/_ResourceItem";
import LargeButton from "../shared/components/buttons/_LargeButton";
import Label from "../shared/components/label/_Label";

// services
import { useGetInspectionsByDayQuery } from "../../redux/features/inspectionLine/inspectionApiSlice";
import * as profileSlice from "../../redux/features/profile/profileSlice";

const Inspection = () => {

  const { selectedDay } = useSelector(state => state.profile);

  const { data, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });

  const [state, setState] = useState({
    cat1: "N/A",
    cat5: "N/A",
    inspectionLine: {
      inspectionDate: '',
      inspectionTime: '',
    }
  });

  const navigate = useNavigate();
  const { inspectionLineId } = useParams();

  const nbOfDeficiencies = 0;
  const cat5Results = ["N/A", "Satisfactory"];
  const cat1Results = ["N/A", "Satisfactory", "Unsatisfactory"];


  //useEffect to get the selected inspectionLine from data.results where the param inspectionLineId
  useEffect(() => {

    if (data && data.results && data.results.length > 0) {
      const line = data.results.find(c => c.inspectionLineId == inspectionLineId);
      setState({ ...state, inspectionLine: line });
    }
  }, [isSuccess]);

  const onBackClick = () => {
    navigate(INTERNAL_ROUTES.SCHEDULER);
  };

  const onInspectionDetailsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onInspectorClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_INSPECTOR.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onBrakeComplianceClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_BRAKE_COMPLIANCE.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onDeficienciesClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };
  const onViewCommentsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_COMMENTS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };
  const onViewDeficiencyClick = () => {

  };

  const onViewPhotosClick = () => {

    navigate(
      INTERNAL_ROUTES.INSPECTION_PHOTOS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );

  };
  const onCompleteClick = () => { };

  const onSelectCat5 = (res) => {
    setState((prev) => ({ ...prev, cat5: res }));
  };

  const onSelectCat1 = (res) => {
    setState((prev) => ({ ...prev, cat1: res }));
  };


  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={inspectionLineId}
            subTitle={new Date(state.inspectionLine.inspectionDate).toLocaleDateString() + ' - ' + moment(state.inspectionLine.inspectionTime, "HH:mm").format("h:mm A")}
            onBack={onBackClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InspectionDetailsBar onInspectionDetailsClick={onInspectionDetailsClick} />

          <Stack direction="vertical" gap={3} className="mt-2 mb-3 justify-content-center">
            <p className="top-border"></p>
            <Label text={"CAT 5 Results"} className={"fw-light"} />
            <ToggleButtonGroup
              name={"cat5"}
              possibleResults={cat5Results}
              selectedResult={state.cat5}
              onSelect={onSelectCat5}
            />

            <p className="top-border"></p>
            <Label text={"CAT 1 Results"} className={"fw-light"} />
            <ToggleButtonGroup
              name={"cat1"}
              possibleResults={cat1Results}
              selectedResult={state.cat1}
              onSelect={onSelectCat1}
            />

            <Stack direction="vertical" gap={2}>
              <p className="top-border"></p>

              <ResourceItem
                description={"BRAKE COMPLIANCE - MACHINE MANUFACTURER"}
                action={(state.inspectionLine.brakeComplianceDisplay !== null ? state.inspectionLine.brakeComplianceDisplay : 'Select Status') + ' - ' + (state.inspectionLine.machineManufacturer)}
                onClick={onBrakeComplianceClick}
              />
              <p className="top-border"></p>
              <ResourceItem
                description={"ELEVATOR INSPECTOR"}
                action={state.inspectionLine.elevatorContactFullName !== null ? (state.inspectionLine.elevatorContactFullName + ' - ' + state.inspectionLine.elevatorContactLicense) : "Select Inspector"}
                onClick={onInspectorClick}
              />
              <p className="top-border"></p>
              <ResourceItem
                description={`DEFICIENCIES (${nbOfDeficiencies})`}
                action={"View Device Deficiencies"}
                onClick={onDeficienciesClick}
              />
              <p className="top-border"></p>
              <ResourceItem
                description={`INSPECTION DEVICE COMMENTS`}
                action={"View Comments"}
                onClick={onViewCommentsClick}
              />
              <p className="top-border"></p>
              <ResourceItem
                description={`DEFICIENCY HISTORY`}
                action={"Previous CAT 1 Results"}
                onClick={onViewDeficiencyClick}
              />
              <p className="top-border"></p>
              <ResourceItem
                description={`INSPECTION PHOTOS`}
                action={"View/Take Photos"}
                onClick={onViewPhotosClick}
              />
            </Stack>
            <p className="top-border"></p>
            <LargeButton
              title="Complete Inspection"
              onClick={onCompleteClick}
            />
          </Stack>
        </Col>
      </Row>
    </Container>
  );



};

export default Inspection;
