import React from "react";

const Profile = () => {
  return (
    <div className="app-profile">
      <h1>Profile</h1>

      let's log out
    </div>
  );
};

export default Profile;
