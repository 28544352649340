import React from "react";
import { Button } from "react-bootstrap";
import Label from "../label/_Label";

const ToggleButtonGroup = ({
  name,
  possibleResults,
  selectedResult,
  onSelect,
}) => {
  const onSelectRadio = (res) => {
    if (onSelect && selectedResult !== res) onSelect(res);
  };

  const buildButtons = () => {
    return possibleResults.map((result, i) => {
      const className = selectedResult === result ? "btn-info" : "btn-light";
      return (
        <Button
          className={`flex-fill btn ${className}`}
          key={i}
          id={`btn-${name}-${i}`}
          type="radio"
          size="lg"
          name={name}
          value={result}
          checked={selectedResult === result}
          onClick={(e) => onSelectRadio(result)}
        >
          <Label text={result} />
        </Button>
      );
    });
  };

  return (
    <div role="group" className="btn-group">      
      {buildButtons()}
    </div>
  );
};

export default ToggleButtonGroup;
