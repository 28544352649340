import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from "moment/moment";

import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../../routing/nav_routes";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header";
import PhoneDetailLine from "./components/_PhoneDetailLine";
import DetailLine from "./components/_DetailLine";

//services
import { useGetInspectionsByDayQuery } from "../../../redux/features/inspectionLine/inspectionApiSlice";

//css

const InspectionDetails = () => {
  let { inspectionLineId } = useParams();
  const navigate = useNavigate();

  const { selectedDay } = useSelector(state => state.profile);

  const { data, isError, isLoading, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, {
    refetchOnMountOrArgChange: true,
  });

  const [inspectionDetails, setInspectionDetails] = useState({
    inspectionDate: '',
    inspectionTime: '',
    deviceName: '',
    inspectionType: '',
    inspectionId: '',
    inspectionLineId: '',
  });



  useEffect(() => {
    if (data) {
      const line = data.results.find(c => c.inspectionLineId == inspectionLineId);
      setInspectionDetails(line);
    }
  }, [isSuccess]);

  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header title={"Inspection details"} onBack={onBackClick} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Stack gap={2} className="px-2">
            <DetailLine
              titleOne={"INSP TIME"}
              detailsOne={moment(inspectionDetails.inspectionTime, "HH:mm").format("h:mm A")}
              titleTwo={"DEVICE"}
              detailsTwo={inspectionDetails.deviceName}
            />
            <DetailLine
              titleOne={"INSP TYPE"}
              detailsOne={inspectionDetails.inspectionType}
              titleTwo={"INSP ID"}
              detailsTwo={inspectionDetails.inspectionId}
            />
            <DetailLine
              titleOne={"BUILDING ADDRESS"}
              detailsOne={inspectionDetails.buildingAddress}
            />
            <DetailLine titleOne={"BOROUGH"} detailsOne={inspectionDetails.borough} />
            <DetailLine
              titleOne={"CUSTOMER"}
              detailsOne={inspectionDetails.customerName}
            />
            <DetailLine
              titleOne={"ELEVATOR CO"}
              detailsOne={inspectionDetails.elevatorCompany}
            />
            <DetailLine
              titleOne={"TESTING CO"}
              detailsOne={inspectionDetails.testingCompany}
            />
            <PhoneDetailLine
              title={"AGENT"}
              name={inspectionDetails.agentFullName}
              phone={inspectionDetails.agentPhone}
            />
            <PhoneDetailLine
              title={"SUPER"}
              name={inspectionDetails.superintendent}
              phone={inspectionDetails.superPhone}
            />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionDetails;
