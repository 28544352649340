import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/user/userManager";
import { EXTERNAL_ROUTES } from "../../routing/nav_routes";

// components
import Tabs from "./components/tabs/_Tabs";

//css
import "./TabLayout.css";

function TabLayout() {
  //TODO: home, schedule, map, profile - any view we want to show the bottom tabs
  const navigate = useNavigate();

  //   useEffect(() => {
  //     isAuthenticated().then((resp) => {
  //       if (!resp) navigate(EXTERNAL_ROUTES.LOGIN);
  //     });
  //   });

  return (
    <div className="tab-layout">
      <Outlet />
      <Tabs />
    </div>
  );
}

export default TabLayout;