import React, { useState, useEffect } from "react";

//css
import "./_InspectionImageLine.css";

// components
import { Stack } from "react-bootstrap";

const InspectionImageLine = ({
  partItem,
  onSelectImage,
  onSelectPart,
}) => {
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  useEffect(() => {
    let fileReader,
      isCancel = false;
      
    if (partItem?.image) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsArrayBuffer(partItem.image);
    }

    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          console.log(result);
          //setFileDataURL(result);
        }
      };
      //fileReader.readAsDataURL(file);
      fileReader.readAsArrayBuffer(file);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file, partItem]);

  const upload = (file) => {
    //console.log(file.files[0]);
    //setFile(file.files[0]);
    onSelectImage(partItem, file.files[0]);
  };

  return (
    
    <Stack gap={3} direction="horizontal" className="mt-2 align-items-center">
      {fileDataURL ? (
        <img className="image" src={fileDataURL} alt="Inspection line" />
      ) : (
        <div className="image d-flex justify-content-center align-items-center">
          <i className="display-6 ri-file-2-line " />
        </div>
      )}
      <h4 className="flex-fill">{partItem?.part?.name ?? "None"}</h4>
      <label
        className="display-6 ri-settings-4-line"
        onClick={() => onSelectPart(partItem)}
      ></label>
      <label className="display-6 ri-camera-line">
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={(e) => upload(e?.target)}
        />
      </label>
    </Stack>
  );
};

export default InspectionImageLine;
