import React from "react";
import { Stack, Badge } from "react-bootstrap";

const NotFound = () => {
  return (
    <Stack direction="horizontal" gap={3} className="justify-content-center">
      <h1>
        <Badge bg="danger">404</Badge> Page Not Found!
      </h1>
    </Stack>
  );
};

export default NotFound;
