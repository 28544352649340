import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { mapPartsArray } from "../../services/utils/utilities";

// components
import InspectionImagesMain from "./components/_InspectionImageMain";
import ResourceList from "../shared/components/resource-list/_ResourceList";

//services
import { INSPECTION_ROUTES_PARAMS, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { useGetInspectionsByDayQuery, useAddInspectionLineImageMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import * as resourceSlice from "../../redux/features/resources/resourceApiSlice";

const buildEmptyParts = (number) => {
	const partItems = [];
	for (let index = 0; index < number; index++) {
		partItems.push({ index: index, part: null, image: null });
	}
	return partItems;
};

const InspectionImages = () => {
	const navigate = useNavigate();
	const { inspectionLineId } = useParams();

	const maxImages = 6;
	const [showPartsResourceForPartItem, setShowPartsResourceForPartItem] = useState(null);

	const { selectedDay } = useSelector(state => state.profile);

	// on page reload - the inspection lines
	const { data, isLoading, isError, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });



	const [state, setState] = useState({
		inspectionLine: null,
		partItems: buildEmptyParts(maxImages),
	});
	const [addImage, mutation] = useAddInspectionLineImageMutation();
	// get from RTKQ
	const resources = resourceSlice.useGetResourcesQuery();
	const elevatorParts = resources.isSuccess
		? mapPartsArray(resources.data.elevatorParts)
		: [];

	//useEffect to get the selected inspectionLine from data.results where the param inspectionLineId
	useEffect(() => {
		if (data) {
			console.log(
				"fetching inspection line id",
				`${inspectionLineId}`,
				data.results
			);
			const line = data.results.find(
				(c) => `${c.inspectionLineId}` === `${inspectionLineId}`
			);
			console.log("found inspecion line", line);
			setState({ ...state, inspectionLine: line });
		}
	}, [isSuccess]);

	const onBackClick = () => {
		navigate(
			INTERNAL_ROUTES.INSPECTION.replace(
				INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
				inspectionLineId
			)
		);
	};

	const onSelectImage = (partItem, image) => {
		console.log("select image", partItem, image);

		partItem.image = image;

		setState((prev) => ({ ...prev }));

		uploadImage(partItem);
	};

	const onSelectPart = (partItem, partResource) => {
		console.log("select part", partItem, partResource);

		partItem.part = partResource;

		setState((prev) => ({ ...prev }));
		setShowPartsResourceForPartItem(null);

		uploadImage(partItem);
	};

	const uploadImage = (partItem) => {
		if (!state.inspectionLine) {
			return;
		}

		if (partItem?.part && partItem?.image) {
			const line = state.inspectionLine;
			const part = partItem.part;
			const image = partItem.image;
			const fileName = `${line.inspectionId}_${line.inspectionLineId}_${part.id}_${partItem.index}`;

			const formData = new FormData();
			formData.append("file", image);

			setState((prev) => ({ ...prev, updating: true, updatedStatus: null }));

			addImage({
				inspectionId: line.inspectionId,
				inspectionLineId: line.inspectionLineId,
				partId: part.id,
				imageIndex: partItem.index,
				formData: formData,
			})
				.then((resp) => {
					if (resp.error) {
						setState((prev) => ({ ...prev, updatedStatus: false }));
						return;
					}

					setState((prev) => ({
						...prev,
						updatedStatus: true,
						updating: false,
					}));
				})
				.catch((resp) =>
					setState((prev) => ({
						...prev,
						updatedStatus: false,
						updating: false,
					}))
				);
		}
	};

	const render = () => {
		if (isLoading) return <div>...Loading!</div>;

		if (isError) return <div>...Failed to load inspection line!</div>;

		if (isSuccess) {
			if (showPartsResourceForPartItem)
				return (
					<ResourceList
						title={"Elevator Part"}
						subTitle={"Select a part"}
						resources={elevatorParts}
						onSelect={(part) =>
							onSelectPart(showPartsResourceForPartItem, part)
						}
						onBack={() => setShowPartsResourceForPartItem(null)}
					/>
				);

			return (
				<InspectionImagesMain
					partItems={state.partItems}
					onSelectImage={onSelectImage}
					onSelectPart={(partItem) => setShowPartsResourceForPartItem(partItem)}
					onBack={onBackClick}
				/>
			);
		}
	};

	return <>{render()}</>;
};

export default InspectionImages;
