//* Resource 


import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../header/_Header";

const ResourceList = ({ title, subTitle, resources, onSelect, onBack }) => {
  const onSelectClick = (value) => {
    //! bubble up selection to parent
    if (onSelect) onSelect(value);
  };

  const buildLines = () => {
    return resources.map((res) => {
      // check if we have an 'item' - if we don't - don't show the res.item, just the res.description
      return (
        <div className="border-top border-1 p-3" key={res.id}>
          <h3 onClick={() => onSelectClick(res)} value={res.id}>
            {res.item === undefined ? (`${res.description}`) : (`${res.item} - ${res.description}`)}
          </h3>
        </div>
      );
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header title={title} subTitle={subTitle} onBack={onBack} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Stack className="p-2">{buildLines()}</Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default ResourceList;
