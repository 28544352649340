import React from "react";
import { Col, Row } from "react-bootstrap";
import LabelGroup from "../../../shared/components/label/_LabelGroup";

const DetailLine = ({ titleOne, detailsOne, titleTwo, detailsTwo }) => {
  return (
    <div className="border-top border-1">
      <Row>
        <Col>
          <LabelGroup text={detailsOne} description={titleOne} />
        </Col>
        <Col>
          <LabelGroup text={detailsTwo} description={titleTwo} containerClass={"text-end"} />
        </Col>
      </Row>
    </div>
  );
};

export default DetailLine;
