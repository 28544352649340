import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../shared/components/header/_Header";

//services
import DeficiencyLine from "./components/_DeficiencyLine";
import * as deficiencySlice from "../../redux/features/deficiencies/deficiencyApiSlice";
import { useDeleteDeficiencyMutation } from "../../redux/features/deficiencies/deficiencyApiSlice";

//css

const InspectionDeficiencies = () => {

  const navigate = useNavigate();
  const { inspectionLineId } = useParams();


  const defApi = deficiencySlice.useGetDeficienciesQuery({ inspectionLineId: inspectionLineId });

  const [deleteDeficiency, { isError, isLoading, isSuccess }] = useDeleteDeficiencyMutation();



  //const [deficiencies, setDeficiencies] = useState([]);

  //useEffect(() => {

  // console.log('defApi.isLoading', defApi.isLoading);
  // console.log('defApi.isSuccess', defApi.isSuccess);
  // if (defApi.data && defApi.data.results && defApi.data.results.length > 0) {

  //   const line = defApi.data.results.find(c => c.inspectionLineId == inspectionLineId);

  //   if (line) {
  //     console.log('line', line);
  //     //setState(prevState => ({ ...prevState, inspectionLine: line, comments: line?.comments }));
  //   } else {
  //     console.warn('Inspection line not found');
  //   }
  // }

  // if (inspApi.data && inspApi.data.results && inspApi.data.results.length > 0) {

  //   const line = inspApi.data.results.find(c => c.inspectionLineId == inspectionLineId);
  //   if (line) {
  //     console.log('line', line);
  //     //setState(prevState => ({ ...prevState, inspectionLine: line, comments: line?.comments }));
  //   } else {
  //     console.warn('Inspection line not found');
  //   }
  // }
  //}, [defApi.isSuccess, defApi.isLoading]);



  const onBackClick = () => {

    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onAddClick = () => {

    // we are adding a new deficiency - pass 0 as the id
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      ).replace(INSPECTION_ROUTES_PARAMS.DEFICIENCY_ID, 0)
    );


  };

  const onOpenDeficiency = (def) => {

    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES_DETAILS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      ).replace(INSPECTION_ROUTES_PARAMS.DEFICIENCY_ID, def.deficiencyId)
    );
  };

  const onDeleteDeficiency = async (def) => {
    // TODO add loader

    try {
      await deleteDeficiency(def.deficiencyId).unwrap();
      console.log(isLoading);

    } catch (error) {

      console.log(error);
    } finally {
      console.log('close modal');
    }


  };




  if (defApi.isError) {
    return <div>Error</div>
  } else if (isLoading) {
    return <div>Loading...</div>
  } else if (defApi.isSuccess) {
    return (
      <Container>
        <Row>
          <Col>
            <Header
              title={"Device Deficiencies"}
              subTitle={"Insert or edit device deficiencies"}
              onBack={onBackClick}
              onAction={onAddClick}
              actionClassName={"ri-add-fill"}
            />
          </Col>
        </Row>
        <Row>
          <Col>

          </Col>
        </Row>
        <Row>
          <Col>
            <Stack gap={2}>{defApi.data.results.map((d, i) => (

              <DeficiencyLine
                key={i}
                deficiency={d}
                onOpen={onOpenDeficiency}
                onDelete={onDeleteDeficiency}
              />
            ))}</Stack>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default InspectionDeficiencies;