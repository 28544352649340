import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

// components

import Header from "../shared/components/header/_Header";
import LargeButton from "../shared/components/buttons/_LargeButton";
import ConfirmModal from "../shared/components/modal/_ConfirmModal";

//services
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useGetInspectionsByDayQuery, useUpdateCommentsMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";


// 

const Comments = () => {

  const [state, setState] = useState({
    inspectionLine: null,
    comments: null,
    showConfirm: false,
    updatedStatus: null,
  });

  const { inspectionLineId } = useParams();
  const navigate = useNavigate();

  // REDUX ------------------------------------------------------------
  // get selected day from profile
  const { selectedDay } = useSelector(state => state.profile);

  // on page reload - the inspection lines
  const { data, isLoading, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });
  const [updateComments, mutation] = useUpdateCommentsMutation();
  // REDUX ------------------------------------------------------------



  const loading = isLoading || mutation.isLoading;
  //useEffect to get the selected inspectionLine from data.results where the param inspectionLineId
  useEffect(() => {
    if (data && data.results && data.results.length > 0) {
      const line = data.results.find(c => c.inspectionLineId == inspectionLineId);
      if (line) {
        setState(prevState => ({ ...prevState, inspectionLine: line, comments: line?.comments }));
      } else {
        console.warn('Inspection line not found');
      }
    }
  }, [isSuccess]);

  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onUpdateClick = () => {
    if (isNullOrWhitespace(state.comments)) {
      setState(prevState => ({ ...prevState, showConfirm: true }));
      return;
    }

    setState(prevState => ({ ...prevState, updatedStatus: null, updating: true }));

    update();
  };

  const isNullOrWhitespace = (value) => {
    if (value === null) return true;
    if (value.length === 0) return true;
    if (value === "") return true;

    for (const c in value) {
      if (c !== " ") return false;
    }

    return true;
  };

  const update = async () => {
    let payload = {
      inspectionLineId: inspectionLineId,
      comments: state.comments,
    };

    let response = await updateComments(payload);
    if (response.error) {
      setState(prevState => ({ ...prevState, updatedStatus: false }));
      return;
    }

    // update the status of the attempt
    setState(prevState => ({ ...prevState, updatedStatus: true, updating: false }));
  };

  const onCommentsChange = (value) => {
    setState(prevState => ({ ...prevState, comments: value, updatedStatus: null }));
  };

  const closeModal = () => {
    setState(prevState => ({ ...prevState, showConfirm: false }));
  };

  const confirmModal = () => {
    setState(prevState => ({ ...prevState, showConfirm: false, updatedStatus: null }));
    update();
  };

  const renderUpdateMessage = () => {

    if (state.updatedStatus === null) return;
    if (state.updatedStatus)
      return (
        <h4 className={`fade text-success alert alert-success show m-0`}>
          Successfully updated!
        </h4>
      );
    if (!state.updatedStatus)
      return (
        <h4 className={`fade text-danger alert alert-danger show m-0`}>
          Failed to update!
        </h4>
      );
  };
  return (
    <Container>
      <ConfirmModal
        show={state.showConfirm}
        text={"Are you sure?"}
        title={"You are about to empty the comments!"}
        onConfirm={confirmModal}
        onClose={closeModal}
      />
      <Row>
        <Col>
          <Header title={"Comments"} onBack={onBackClick} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Stack gap={2} className="px-2">
            {renderUpdateMessage()}
            <label htmlFor="comments" className="form-label ">
              <h4 className={`fw-light m-0`}>COMMENTS</h4>
            </label>
            <textarea
              className="form-control fs-4"
              id="comments"
              rows="15"
              onChange={(e) => onCommentsChange(e.target.value)}
              value={state.comments ?? ""}
            ></textarea>
            <LargeButton title="Update" onClick={onUpdateClick} disabled={loading} loading={loading} />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default Comments;
