import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSPECTION_ROUTES_PARAMS, INTERNAL_ROUTES } from "../../routing/nav_routes";
import { useSelector } from 'react-redux';

// components
import InspectorMain from "./components/_InspectorMain";

//services
import { INSPECTOR_COMPONENTS, INSPECTOR_CONST } from "./components/_InspectorConst";
import ResourceList from "../shared/components/resource-list/_ResourceList";
import { useGetInspectionsByDayQuery, useUpdateInspectorMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import * as resourceSlice from "../../redux/features/resources/resourceApiSlice";
import { mapElevatorContacts } from "../../services/utils/utilities";

//css

const Inspector = () => {
  const [state, setState] = useState({ name: "N/A", license: null });
  const { inspectionLineId } = useParams();
  const navigate = useNavigate();

  const { selectedDay } = useSelector(state => state.profile);
  const { data, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });

  const [getElevatorContacts] = resourceSlice.useLazyGetElevatorContactsQuery();

  // update inspector in db
  const [updateInspector, mutation] = useUpdateInspectorMutation();

  const [contactDetails, setContactDetails] = useState({
    inspectionLineId: 0,
    elevatorContactId: 0,
    testingCompanyId: 0,
    fullName: '',
    license: ''
  });

  const [elevatorContacts, setElevatorContacts] = useState([]);

  useEffect(() => {
    if (data && data.results && data.results.length > 0) {
      // Find the inspection line with the matching inspectionLineId
      const line = data.results.find(c => c.inspectionLineId == inspectionLineId);

      setContactDetails({
        inspectionLineId: line.inspectionLineId,
        testingCompanyId: line.testingCompanyId,
        elevatorContactId: line.elevatorContactId,
        fullName: line.elevatorContactFullName,
        license: line.elevatorContactLicense
      });

      loadContactsFromDb(line.testingCompanyId);
    }
  }, [isSuccess]);

  const loadContactsFromDb = async (testingCompanyId) => {
    let response = await getElevatorContacts(testingCompanyId);
    setElevatorContacts(mapElevatorContacts(response.data) ?? []);
  };

  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };


  const backToMainComponent = () => {
    setState((prev) => ({
      ...prev,
      componentToShow: INSPECTOR_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    console.log('onShowComponent', comp);
    setState((prev) => ({
      ...prev,
      componentToShow: comp,
    }));
  };


  const onSelectInspector = async (opt) => {
    // update mutation into redux
    // we need the id of the elevator contact
    // the description of the elevator contact has the name and license in one

    let payload = {
      inspectionLineId: contactDetails.inspectionLineId,
      elevatorContactId: opt.id
    };

    let response = await updateInspector(payload);

    if (response.error) {
      // handle error
      console.log('error');
      //setState(prevState => ({ ...prevState, updatedStatus: false }));
      return;
    }

    setContactDetails((prev) => ({
      ...prev,
      elevatorContactId: opt.id,
      fullName: opt.fullName,
      license: opt.license
    }));


    // change view to main component
    setState((prev) => ({ ...prev, componentToShow: INSPECTOR_COMPONENTS.MAIN, }));
  };

  const render = () => {
    switch (state.componentToShow) {
      case INSPECTOR_COMPONENTS.INSPECTOR:
        return (
          <ResourceList
            title={INSPECTOR_CONST.SELECT_INSPECTOR_TITLE}
            resources={elevatorContacts}
            onSelect={onSelectInspector}
            onBack={backToMainComponent}
          />
        );
      default:
        return (
          <InspectorMain
            elevatorContacts={elevatorContacts}
            state={contactDetails}
            //setState={setState}
            onSelect={onSelectInspector}
            onShowComponent={onShowComponent}
            onBack={onBackClick}
          />
        );
    }
  };

  return <div>{render()}</div>;
};

export default Inspector;
