export const ENDPOINTS = {

    //API: 'http://localhost:5242/api',
    API: 'https://mobile-api-dev.azurewebsites.net/api',

}

export const VERSION = '1.0.45';

export const API_METHOD = {
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE'
};

export const LOCAL_STORAGE = {
    PROFILE: 'live-profile',
    TOKEN: 'live-token'
};

export const ENV_VARIABLE_NAMES = {
    LOGIN_URL: 'REACT_APP_API_LOGIN',
    LOGOUT_URL: 'REACT_APP_API_LOGOUT',
    GET_CURRENT_USER: 'REACT_APP_API_USERS_GET_CURRENT_USER'
};

export const AUTH_CACHE_KEYS = {
    IS_USER_AUTHENTICATED_KEY: 'IS_USER_AUTHENTICATED_KEY',
    USER_AUTHENTICATED_INFO_KEY: 'USER_AUTHENTICATED_INFO_KEY',
}