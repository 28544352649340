import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Container, Row, Stack } from "react-bootstrap";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

// components
import MarkerWithInfoWindow from "./_MarkerWithInfoWindow";

// css
import "./map.css";

//services
import { useGetInspectionsByDayQuery } from "../../redux/features/inspectionLine/inspectionApiSlice";
import { mapStyles } from "./mapConsts";



const ScheduleMap = () => {
  const [selectedDayBuildingsMap, setSelectedDayBuildingsMap] = useState([]);
  const pendingInspectionsMap = [];
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0, zoom: 12 });
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const mapContainer = useRef(null);


  const { selectedDay } = useSelector(state => state.profile);

  // on page reload - the inspection lines
  const { data, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });



  useEffect(() => {
    if (isSuccess && data?.results && data.results.length > 0) {
      const result = Object.groupBy(data.results, (item) => item.buildingId);
      const buildings = [];
      const inspectionGroups = Object.values(result);

      for (let index = 0; index < inspectionGroups.length; index++) {
        const inspectionGroup = inspectionGroups[index];
        const first = inspectionGroup[0];
        const building = {
          buildingId: first.buildingId,
          inspectionId: first.inspectionId,
          borough: first.borough,
          buildingCity: first.buildingCity,
          buildingAddress: first.buildingAddress,
          inspectionLines: inspectionGroup,
          latitude: first.latitude,
          longitude: first.longitude,
        };

        buildings.push(building);
      }

      const firstBuilding = buildings[0];

      setSelectedDayBuildingsMap(buildings);
      setMapCenter((prev) => ({
        ...prev,
        lat: firstBuilding.latitude,
        lng: firstBuilding.longitude,
      }));
    }
  }, [isSuccess]);

  const onMarkerClickAssigned = (item) => {
    // debugger;
    setSelectedBuilding(item);
    setMapCenter((prev) => ({
      ...prev,
      lat: item.latitude,
      lng: item.longitude,
    }));

    //dispatch(scheduleActions.setSelectedInspIdFromMap(item.inspectionId));
    //dispatch(scheduleActions.setMapCenter({ lat: item.latitude, lng: item.longitude, zoom: 16 }));
    //dispatch(scheduleActions.setMapTab(3));
  };

  const onMarkerModalClose = () => {
    setSelectedBuilding(null);
  };

  const onMarkerClickPending = (item, index, e) => {
    //dispatch(scheduleActions.setSelectedInspIdFromMap(item.inspectionId));
    //dispatch(scheduleActions.setMapCenter({ lat: item.latitude, lng: item.longitude, zoom: 16 }));
    //dispatch(scheduleActions.setMapTab(3));
  };

  const onBuildingClick = (building) => {
    onMarkerClickAssigned(building);
    mapContainer.current?.scrollIntoView();
  };

  const isBuildingSelected = (building) => {
    return (
      selectedBuilding && selectedBuilding?.buildingId === building.buildingId
    );
  };

  const renderBuildings = () => {
    return selectedDayBuildingsMap.map((building, i) => {
      const selectedBorder = isBuildingSelected(building)
        ? "border-primary"
        : "";
      return (
        <div
          key={i}
          className={`card m-0 border border-2 ${selectedBorder}`}
          onClick={() => onBuildingClick(building)}
        >
          <div className="card-body">
            <h5 className="card-title card-link">{`Inspection ${building.inspectionId}`}</h5>
            <p className="card-text">{`${building.borough}, ${building.buildingAddress}`}</p>
            <a className="link-info">Select to center on map.</a>
          </div>
        </div>
      );
    });
  };

  //Official docs here https://visgl.github.io/react-google-maps/docs

  const handleCameraChange = useCallback(({ detail }) => {
    //console.log("handleCameraChange", detail);
    setMapCenter(() => ({
      zoom: detail.zoom,
      lat: detail.center.lat,
      lng: detail.center.lng,
    }));
  });

  return (
    <>
      <Container className="container-fluid">
        <Row className="map-row">
          <div className="col-xl-10 mb-2 border">
            <div className="google-maps" ref={mapContainer}>
              <APIProvider apiKey={"AIzaSyDTnuvPuXN7gcUoO0ebgvBGUosJiQURKhY"}>
                <Map
                  zoom={mapCenter.zoom}
                  onCameraChanged={handleCameraChange}
                  center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
                  mapId="SIERRA_MAP_ID"
                  reuseMaps={true}
                  colorScheme={'LIGHT'}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                //TODO: Apparently you can't have both mapId and styles, map is not working without mapId,
                //TODO: Ask Cay whats with the styles
                // styles={[...mapStyles]}
                >
                  {selectedDayBuildingsMap &&
                    selectedDayBuildingsMap.map((item, index) => {
                      return (
                        <MarkerWithInfoWindow
                          key={index}
                          onMarkerClick={() => onMarkerClickAssigned(item)}
                          onCloseInfoWindow={() => onMarkerModalClose(item)}
                          title={`Inspection ${item.inspectionId}`}
                          info={item.borough + "\n" + item.buildingAddress}
                          position={{ lat: item.latitude, lng: item.longitude }}
                          showInfoWindow={isBuildingSelected(item)}
                        />
                      );
                    })}

                  {/* {pendingInspectionsMap.length !== 0 &&
                    pendingInspectionsMap.map((item, index) => {
                      return (
                        <Marker
                          onClick={(e) => onMarkerClickPending(item, index, e)}
                          key={index}
                          title={item.address}
                          name={"Home sweet home!"}
                          position={{ lat: item.latitude, lng: item.longitude }}
                        ></Marker>
                      );
                    })} */}
                </Map>
              </APIProvider>
            </div>
          </div>
          <div className="col-xl-2 mb-2 buildings">
            <Stack direction="vertical" gap={3} className="justify-content-top">
              {renderBuildings()}
            </Stack>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ScheduleMap;
