import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// components


// services
import { INTERNAL_ROUTES } from "../../routing/nav_routes";
import * as user from "../../services/user/userManager";



const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    user.isAuthenticated().then((resp) => {
      if (resp) {
        // user is aunthenticated

        // make call to get resources from api and add to redux
        //getResourceBundle();

        // redirect to 
        debugger;
        navigate(INTERNAL_ROUTES.HOME);
      }
    });
  }, [navigate]);

  const loginUser = () => {

    // make call to get resources from api and add to redux
    //getResourceBundle();
    user.login();
  };

  // const getResourceBundle = async () => {
  //   // api call here
  //   //let resp = await getResources();

  //   dispatch(resourceSlice.setResources());
  //   console.log('resources getting')
  // };

  return (
    <div className="app-login">
      <h1>Login</h1>

      <Button variant="success" onClick={() => loginUser()}>
        Sign in with AD
      </Button>
    </div>
  );
};

export default Login;
