import React from "react";
import { Stack } from "react-bootstrap";

//css
import "./_DeficiencyLine.css";
import IconButton from "../../shared/components/buttons/_IconButton";
import Label from "../../shared/components/label/_Label";

const DeficiencyLine = ({ deficiency, onOpen, onDelete }) => {
  const { elevatorPart, violation, remedy } = deficiency;

  return (
    <div className="border-top border-5">
      <Stack direction="horizontal" gap={5} className="align-items-center">
        <div className="flex-fill text-start">
          <Label text={`Building Responsibility: ${elevatorPart.isBuildingResponsibility === true ? `Yes` : `No`} `} />
          <Label text={`Elevator Part: ${elevatorPart.description} `} />
          <Label text={`Violation: ${violation.description}`} />
          <Label text={`Remedy: ${remedy.description}`} />
        </div>
        <IconButton
          title={"Delete deficiency"}
          iconClass={"ri-delete-bin-7-line"}
          size={"md"}
          onClick={() => onDelete(deficiency)}
        />
        <IconButton
          title={"Open deficiency"}
          iconClass={"ri-arrow-right-s-line"}
          size={"md"}
          onClick={() => onOpen(deficiency)}
        />
      </Stack>
    </div>
  );
};

export default DeficiencyLine;
