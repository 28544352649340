import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import * as dateHelper from "../../../../services/utils/utilities";
import "./_SchedulerHeader.css";
import Label from "../../../shared/components/label/_Label";
import IconButton from "../../../shared/components/buttons/_IconButton";

const SchedulerHeader = ({ schedulesCount, forDate, onBack, onForward }) => {
  const monthName = dateHelper.getMonthName(forDate);
  const dayName = dateHelper.getDayName(forDate);
  const dayOfMonth = forDate.getDate();

  const onBackClick = () => {
    if (onBack) onBack();
  };

  const onForwardClick = () => {
    if (onForward) onForward();
  };

  return (
    <div className="px-2">
      <Row className="align-items-center justify-content-between">
        <Col className="col-auto  me-auto">
          <IconButton
            title="Back"
            onClick={onBackClick}
            iconClass={"ri-arrow-left-s-line"}
            size={"lg"}
          />
        </Col>
        <Col>
          <h3>
            {dayName}, {monthName}
          </h3>
          <h2>{dayOfMonth}</h2>
          <h3>{schedulesCount} total</h3>
        </Col>
        <Col className="col-auto">
          <IconButton
            title="Forward"
            onClick={onForwardClick}
            iconClass={"ri-arrow-right-s-line"}
            size={"lg"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SchedulerHeader;
