import React from "react";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES, INSPECTION_ROUTES_PARAMS, } from "../../routing/nav_routes";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment/moment";

//components
import { Spinner, Stack } from "react-bootstrap";
import SchedulerHeader from "./components/header/_SchedulerHeader";
import SchedulerLine from "./components/line/_SchedulerLine";

//services

import { useGetInspectionsByDayQuery } from "../../redux/features/inspectionLine/inspectionApiSlice";

// redux
import * as profileSlice from "../../redux/features/profile/profileSlice";


const Scheduler = () => {
  const dispatch = useDispatch();
  const { selectedDay } = useSelector(state => state.profile);

  const { data, isError, isLoading, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, {
    refetchOnMountOrArgChange: true,
  });

  //const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const onLineClick = (line) => {
    // set lineId to route and navigate to inspection line
    navigate(
      INTERNAL_ROUTES.INSPECTION
        .replace(INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID, line.inspectionLineId));
  };


  const onNavigateBack = () => {
    let newSelectedDay = {
      date: moment(new Date(selectedDay.date)).subtract(1, 'days').format('M-D-YYYY'),
      dayOfWeek: moment(new Date(selectedDay.date)).subtract(1, 'days').format('dddd'),
    };

    updateSelectedDay(newSelectedDay);
  };

  const onNavigateForward = () => {
    let newSelectedDay = {
      date: moment(new Date(selectedDay.date)).add(1, 'days').format('M-D-YYYY'),
      dayOfWeek: moment(new Date(selectedDay.date)).add(1, 'days').format('dddd'),
    };

    updateSelectedDay(newSelectedDay);
  };

  const updateSelectedDay = (paload) => {
    dispatch(profileSlice.setSelectedDay(paload));
  };


  const buildLines = () => {
    return data.results.map((line, i) => {
      return (
        <SchedulerLine
          inspectionLine={line}
          onCardSelect={onLineClick}
          key={i}
        />
      );
    });
  };


  if (isError) {
    return <div>Error</div>
  } else if (isLoading) {
    return <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  } else if (isSuccess) {
    return (
      <>

        <div className="app-schedule" style={{ marginBottom: '100px' }}>

          <div>
            <SchedulerHeader
              schedulesCount={data.results?.length}
              forDate={new Date(selectedDay.date)}
              onBack={onNavigateBack}
              onForward={onNavigateForward}
            />
            <Stack direction="vertical" className="bg-success-lighten">
              {buildLines()}
            </Stack>
          </div>

        </div>
      </>

    );
  }
};
export default Scheduler;