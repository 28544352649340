import { AUTH_CACHE_KEYS } from "../constants/appConsts";

export async function isAuthenticated() {
  await getCurrentUser();
  return (
    localStorage.getItem(AUTH_CACHE_KEYS.IS_USER_AUTHENTICATED_KEY) === "true"
  );
}

export async function setCurrentUser(user) {
  localStorage.setItem(AUTH_CACHE_KEYS.IS_USER_AUTHENTICATED_KEY, "true");
  localStorage.setItem(AUTH_CACHE_KEYS.USER_AUTHENTICATED_INFO_KEY, user);
}

export async function getCurrentUser() {
  return localStorage.getItem(AUTH_CACHE_KEYS.USER_AUTHENTICATED_INFO_KEY);
}

export function removeCurrentUser() {
  localStorage.setItem(AUTH_CACHE_KEYS.IS_USER_AUTHENTICATED_KEY, "false");
  localStorage.removeItem(AUTH_CACHE_KEYS.USER_AUTHENTICATED_INFO_KEY);
}
