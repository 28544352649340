import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API + '/deficiency/';

//! make api call to get resources

// no initial state for this service
// it will return and save to the api for us

export const deficiencyApi = createApi({
    reducerPath: 'deficiencies',
    baseQuery: fetchBaseQuery({ baseUrl: baseController }),
    tagTypes: ['Deficiencies'],
    endpoints: (builder) => ({
        getDeficiencies: builder.query({
            query: (params) => ({
                url: `GetDeficiencies/${params.inspectionLineId}`,
                method: 'GET',
                params: {
                    // Add any additional query parameters here
                    // For example:
                    // status: params.status,
                    // date: params.date,
                },
            }),
            providesTags: ['Deficiencies'],
        }),
        upsertDeficiency: builder.mutation({
            query: (body) => ({
                url: 'UpsertDeficiency',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Deficiencies'],
        }),
        deleteDeficiency: builder.mutation({
            query: (deficiencyId) => ({
                url: `DeleteDeficiency/${deficiencyId}`,
                method: 'GET'
            }),
            selectFromResult: ({ data, error, isLoading, isSuccess }) => ({
                data,
                error,
                isLoading,
                isSuccess
            }),
            invalidatesTags: ['Deficiencies'],
        }),
    }),
});


//! use lazy to allow us to hit the api when we need to on demand
export const {
    useLazyGetDeficienciesQuery,
    useGetDeficienciesQuery,
    useUpsertDeficiencyMutation,
    useDeleteDeficiencyMutation
} = deficiencyApi;