import { ENV_VARIABLE_NAMES } from "../constants/appConsts";

export async function navigateToLogin() {
  const apiLoginUrl = process.env[ENV_VARIABLE_NAMES.LOGIN_URL];
  console.log("Browser navigating to", apiLoginUrl);
  window.location.href = apiLoginUrl;
}

export async function getCurrentUser() {
  try {
    const getCurrentUserUrl = process.env[ENV_VARIABLE_NAMES.GET_CURRENT_USER];
    const resp = await fetch(getCurrentUserUrl);
    const status = resp?.status;

    if (status !== 200) {
      return null;
    }

    const userJson = await resp.json();
    return userJson;
  } catch (error) {
    throw error;
  }
}

export function logout() {
  const apiLoginUrl = process.env[ENV_VARIABLE_NAMES.LOGIN_URL];
  console.log("Browser navigating to", apiLoginUrl);
  window.location.href = apiLoginUrl;
}
