import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';


// services
import * as profileSlice from "../../redux/features/profile/profileSlice";
import { useLazyGetResourcesQuery } from "../../redux/features/resources/resourceApiSlice";
import { VERSION } from "../../services/constants/appConsts";
//import * as resourceSlice from "../../redux/features/resources/resourceApiSlice";


const Home = () => {
  const dispatch = useDispatch();
  //const { data, isError, isLoading, isSuccess } = useGetResourcesQuery();
  const [getResources] = useLazyGetResourcesQuery();

  const profile = useSelector(state => state.profile);
  console.log('profile', profile);



  const onSetNameTest = () => {
    dispatch(profileSlice.setProfile());
  };

  const onClearName = () => {
    dispatch(profileSlice.clearProfile());
  };



  const onSetResources = async () => {
    //const { data, isError, isLoading, isSuccess } = await getResources();
    //const [trigger, items] = api.endpoints.getBuisnessAreas.useLazyQuery();
    let response = await getResources();
    console.log('response', response);
    console.log('error', response.isError);
    console.log('is loading', response.isLoading);
    console.log('is success', response.isSuccess);
    console.log(response.data);
    console.log('manually set resources');


    //dispatch(resourceSlice.setResources());
  };


  return (
    <div style={{ flex: 1, height: '100%', flexDirection: 'column' }}>

      <h1>Home View</h1>
      {profile.firstName}
      {/* <div className="bg-success" style={{ flexGrow: 3, alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <Button variant='info' className="text-center" onClick={onSetNameTest}>Set Name</Button>
      </div>
      <div className="bg-warning" style={{ flexGrow: 2, alignItems: 'center', flex: 2, justifyContent: 'center' }}>
        <Button variant='info' className="text-center" onClick={onClearName}>Clear Name</Button>
      </div>
      <div className="bg-danger" style={{ flexGrow: 1, alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <Button variant='info' className="text-center" onClick={onSetResources}>Test</Button>
      </div> */}
      <h1>Welcome!</h1><br /><br />
      <h3>Rick! <br />You have some work to do today!<br /><br />
        <br /><br /><br /><br />
        We believe in you!!</h3>

      <br /><br /><br /><br />

      <h3>v: {VERSION}</h3>


    </div >



  );
};

export default Home;
