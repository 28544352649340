import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../routing/nav_routes";
import { useSelector } from 'react-redux';

// components
import BrakeComplianceMain from "./components/_BrakeComplianceMain";
import ResourceList from "../shared/components/resource-list/_ResourceList";

//services
import { BRAKE_COMPLIANCE_COMPONENTS, BRAKE_COMPLIANCE_CONST } from "./components/_BrakeComplianceConst";
import { mapManufacturers } from "../../services/utils/utilities";


// redux
import { useGetInspectionsByDayQuery, useUpdateBrakeComplianceMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import * as resourceSlice from "../../redux/features/resources/resourceApiSlice";

const BrakeCompliance = () => {
  const navigate = useNavigate();
  const { inspectionLineId } = useParams();

  // REDUX ------------------------------------------------------------
  // get selected day from profile
  const { selectedDay } = useSelector(state => state.profile);

  // on page reload - the inspection lines
  const { data, isSuccess } = useGetInspectionsByDayQuery(selectedDay?.date, { refetchOnMountOrArgChange: true });

  const [updateBrakeCompliance, mutation] = useUpdateBrakeComplianceMutation();

  //get manufacturers from resource rtkq
  const resources = resourceSlice.useGetResourcesQuery();
  const { manufacturers } = resources.isSuccess ? { manufacturers: mapManufacturers(resources.data.manufacturers) } : { manufacturers: [] };
  // REDUX ------------------------------------------------------------

  const [state, setState] = useState({
    brakeCompliance: "N/A",
    manufacturer: { id: 1, name: "N/A" },
  });

  const [complianceDetails, setComplianceDetails] = useState({
    deviceId: 0,
    brakeCompliance: '',
    brakeComplianceDisplay: '',
    machineManufacturer: ''
  });


  useEffect(() => {
    if (data && data.results && data.results.length > 0) {
      // Find the inspection line with the matching inspectionLineId
      const line = data.results.find(c => c.inspectionLineId == inspectionLineId);


      setComplianceDetails({
        deviceId: line.deviceId,
        brakeCompliance: line.brakeCompliance,
        brakeComplianceDisplay: line.brakeComplianceDisplay,
        machineManufacturer: line.machineManufacturer
      });
    }
  }, [isSuccess]);



  const onBackClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };






  const backToMainComponent = () => {
    setState(prevState => ({
      ...prevState,
      componentToShow: BRAKE_COMPLIANCE_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    // show main component or the manufactuerers list component
    setState((prevState) => ({
      ...prevState,
      componentToShow: comp,
    }));
  };



  const onSelectManufacturer = (opt) => {
    // selection from the list of manufacturers    
    let payload = {
      deviceId: complianceDetails.deviceId,
      brakeCompliance: complianceDetails.brakeCompliance,
      brakeComplianceDisplay: complianceDetails.brakeComplianceDisplay,
      machineManufacturer: opt.description
    };

    updateComplianceDetailsToDb(payload);

    setState(prevState => ({
      ...prevState,
      componentToShow: BRAKE_COMPLIANCE_COMPONENTS.MAIN,
    }));
  };


  const onBrakeComplianceChange = (compDetails) => {
    // update from button group

    // user has selected a brake compliance status change
    let payload = {
      deviceId: compDetails.deviceId,
      brakeCompliance: compDetails.brakeCompliance,
      brakeComplianceDisplay: compDetails.brakeComplianceDisplay,
      machineManufacturer: compDetails.machineManufacturer
    };

    updateComplianceDetailsToDb(payload)
  };



  const updateComplianceDetailsToDb = async (payload) => {
    // hit redux api to update the database

    let response = await updateBrakeCompliance(payload);

    if (response.error) {
      // handle error
      console.log('error');
      //setState(prevState => ({ ...prevState, updatedStatus: false }));
      return;
    }

    setComplianceDetails(payload);
  };



  const onUpdate = async (compDetails) => {

    let payload = {
      deviceId: compDetails.deviceId,
      brakeCompliance: compDetails.brakeCompliance,
      machineManufacturer: compDetails.machineManufacturer,
    };

    console.log('payload', payload);
    let response = await updateBrakeCompliance(payload);
    if (response.error) {
      // handle error
      console.log('error');
      //setState(prevState => ({ ...prevState, updatedStatus: false }));
      return;
    }

    // update the status of the attempt
    //setState(prevState => ({ ...prevState, updatedStatus: true, updating: false }));
    setComplianceDetails(compDetails);
  };



  const render = () => {
    // render components
    switch (state.componentToShow) {
      case BRAKE_COMPLIANCE_COMPONENTS.MANUFACTURER:
        // show the resource list
        return (
          <ResourceList
            title={BRAKE_COMPLIANCE_CONST.SELECT_MANUFACTURER_DESCRIPTION}
            resources={manufacturers}
            onSelect={onSelectManufacturer}
            onBack={backToMainComponent}
          />
        );


      default:
        // we show the main component with read only
        return (
          <BrakeComplianceMain
            complianceDetails={complianceDetails}
            onUpdate={onBrakeComplianceChange}
            onShowComponent={onShowComponent}
            onBack={onBackClick}
          />
        );


    }
  };

  return <div>{render()}</div>;
};

export default BrakeCompliance;
