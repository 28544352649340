import React from "react";
import { Outlet } from "react-router-dom";
import "./ExternalLayout.css";

function ExternalLayout() {
  return (
    <div className="external-layout">
      <header className="external-layout-header">
        <h1>Public App</h1>
      </header>
      <div className="external-layout-container">
        <Outlet />
      </div>
    </div>
  );
}

export default ExternalLayout;
