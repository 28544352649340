import React from "react";
import { Link } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../../routing/nav_routes";
import "./_Tabs.css";
import { Container, Navbar, Stack } from "react-bootstrap";

const Tabs = () => {








  return (
    <>
      <Navbar expand="md" fixed="bottom" className="bg-body-tertiary justify-content-center">
        <Stack direction="horizontal" gap={5} className="tabs-links-container">
          <Link to={INTERNAL_ROUTES.HOME}>
            <i className="fs-1 ri-home-line"></i>
          </Link>
          <Link to={INTERNAL_ROUTES.SCHEDULER}>
            <i className="fs-1 ri-calendar-2-line"></i>
          </Link>
          <Link to={INTERNAL_ROUTES.MAP}>
            <i className="fs-1 ri-map-pin-line"></i>
          </Link>
          <Link to={INTERNAL_ROUTES.PROFILE}>
            <i className="fs-1 ri-user-3-line"></i>
          </Link>
        </Stack>
      </Navbar>
    </>
  );
};

export default Tabs;
