import * as api from "../api/authApi";
import * as store from "./userStore";

export async function isAuthenticated() {
  try {
    const result = await api.getCurrentUser();
    if (result) {
      await store.setCurrentUser(result);
      return true;
    }
  } catch { }

  await store.removeCurrentUser();
  return false;
}

export async function login() {
  await store.removeCurrentUser();
  await api.navigateToLogin();
}

export async function getCurrentUser() {
  console.log("getCurrentUser");
  return (await store.getCurrentUser()) ?? null;
}

export function logout() { }
