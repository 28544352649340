import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import * as routes from "./routing/nav_routes_builder.js";
import reportWebVitals from "./reportWebVitals.js";
import "regenerator-runtime/runtime";
import { store } from "./redux/store.js";
import { Provider } from "react-redux";

//import "bootstrap/dist/css/bootstrap.min.css";

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";

import "./index.css";

const router = createBrowserRouter(routes.build());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
