import React, { useEffect } from "react";
import * as user from "../../services/user/userManager";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from "../../routing/nav_routes";

const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    user
      .isAuthenticated()
      .then((resp) => {
        if (resp) {
          navigate(INTERNAL_ROUTES.ROOT);
        } else {
          navigate(EXTERNAL_ROUTES.LOGIN);
        }
      })
      .catch((error) => {
        navigate(EXTERNAL_ROUTES.LOGIN);
      });
  },[navigate]);

  return (
    <div className="app-login-callback">
      <h1>Loading...</h1>
    </div>
  );
};

export default LoginCallback;
