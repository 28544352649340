import React from "react";
import { Stack } from "react-bootstrap";
import "./_PhoneDetailLine.css";
import IconButton from "../../../shared/components/buttons/_IconButton";
import LabelGroup from "../../../shared/components/label/_LabelGroup";

const PhoneDetailLine = ({ title, name, phone }) => {
  //TODO: add phone call

  const onCallPhone = () => {
    console.log("calling phone", phone);
  };

  return (
    <Stack direction="horizontal" className="border-top border-1">
      <LabelGroup text={name} description={title} subText={phone} />
      <IconButton
        title={`Call ${name}`}
        iconClass={"ri-phone-fill"}
        size={"md"}
        onClick={onCallPhone}
      />
    </Stack>
  );
};

export default PhoneDetailLine;
