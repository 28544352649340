import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { resourceApi } from './features/resources/resourceApiSlice';
import { deficiencyApi } from './features/deficiencies/deficiencyApiSlice';
import { inspectionApi } from './features/inspectionLine/inspectionApiSlice';
import profileReducer from './features/profile/profileSlice';

export const store = configureStore({
    reducer: {
        [resourceApi.reducerPath]: resourceApi.reducer,
        [deficiencyApi.reducerPath]: deficiencyApi.reducer,
        [inspectionApi.reducerPath]: inspectionApi.reducer,
        profile: profileReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(resourceApi.middleware, deficiencyApi.middleware, inspectionApi.middleware),
});

setupListeners(store.dispatch);