import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../../../services/constants/appConsts";

const baseController = ENDPOINTS.API + "/inspection/";

//! make api call to get resources

// no initial state for this service
// it will return and save to the api for us

export const inspectionApi = createApi({
  reducerPath: 'inspections',
  baseQuery: fetchBaseQuery({ baseUrl: baseController }),
  tagTypes: ['Inspections'],
  endpoints: (builder) => ({
    getInspectionsByDay: builder.query({
      query: (selectedDate) => ({
        url: `GetInspectionByDay`,
        method: 'GET',
        params: {
          // Add any additional query parameters here
          // For example:
          // status: params.status,
          date: selectedDate,
        },
      }),
      providesTags: ['Inspections'],
    }),
    updateBrakeCompliance: builder.mutation({
      query: (body) => ({
        url: 'UpdateBrakeCompliance',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Inspections'],
    }),
    updateInspector: builder.mutation({
      query: (body) => ({
        url: 'UpdateInspector',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Inspections'],
    }),
    updateComments: builder.mutation({
      query: (payload) => ({
        url: 'UpdateComments',
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ['Inspections'],
    }),
    addInspectionLineImage: builder.mutation({
      query: (payload) => ({
        url: `AddImage?InspectionId=${payload.inspectionId}&InspectionLineId=${payload.inspectionLineId}&PartId=${payload.partId}&ImageIndex=${payload.index}`,
        method: "POST",
        body: payload.formData,
      }),
    }),
  }),
});

//! use lazy to allow us to hit the api when we need to on demand
export const {
  useLazyGetInspectionsByDayQuery,
  useGetInspectionsByDayQuery,
  useUpdateInspectorMutation,
  useUpdateCommentsMutation,
  useUpdateBrakeComplianceMutation,
  useAddInspectionLineImageMutation
} = inspectionApi;
