import React from "react";
import { Button } from "react-bootstrap";

const LargeButton = ({ title, className, onClick, disabled, loading }) => {
  const renderSpinner = () => {
    if (loading)
      return (
        <span className="spinner-border text-white spinner-border-sm"></span>
      );
  };

  return (
    <Button
      variant="info"
      size="lg"
      title="title"
      className={`d-flex justify-content-center align-items-center gap-2 fs-3 ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {renderSpinner()}
      {title}
    </Button>
  );
};

export default LargeButton;
