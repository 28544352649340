import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/user/userManager";
import { EXTERNAL_ROUTES } from "../../routing/nav_routes";
import "./InternalLayout.css";

function InternalLayout() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   isAuthenticated().then((resp) => {
  //     if (!resp) navigate(EXTERNAL_ROUTES.LOGIN);
  //   });
  // });

  return <Outlet />;
}

export default InternalLayout;
