import React from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header";
import InspectionImageLine from "./_InspectionImageLine";

const InspectionImagesMain = ({
  partItems,
  onSelectImage,
  onSelectPart,
  onBack,
}) => {
  const buildItems = () => {
    return partItems?.map((partItem, i) => {
      return (
        <InspectionImageLine
          key={i}
          partItem={partItem}
          onSelectImage={onSelectImage}
          onSelectPart={onSelectPart}
        />
      );
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Inspection Photos"}
            subTitle={"You can upload a maximum of 6 images"}
            onBack={onBack}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Stack gap={3} className="mt-2">
            {buildItems()}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default InspectionImagesMain;
