import React from "react";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import IconButton from "../buttons/_IconButton";

const Header = ({ title, subTitle, onBack, actionClassName, onAction }) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBack) onBack();
    else navigate(-1);
  };

  const onActionClick = () => {
    if (onAction) onAction();
  };

  const renderAction = () => {
    if (actionClassName) {
      return (
        <IconButton
          title={"Action"}
          iconClass={actionClassName}
          size={"lg"}
          onClick={onActionClick}
        />
      );
    }
  };

  return (
    <Stack direction="horizontal" gap={3} className="justify-content-between mb-3">
      <IconButton
        title={"Back"}
        iconClass={"ri-arrow-left-s-line"}
        size={"lg"}
        onClick={onBackClick}
      />
      <Stack direction="horizontal" gap={3}>
        <div className="text-end">
          <h2>{title}</h2>
          {subTitle ? <h3>{subTitle}</h3> : <></>}
        </div>
        {renderAction()}
      </Stack>
    </Stack>
  );
};

export default Header;
