import React from "react";
import { Stack } from "react-bootstrap";
import IconButton from "../../../shared/components/buttons/_IconButton";
import Label from "../../../shared/components/label/_Label";

const InspectionDetailsBar = ({ onInspectionDetailsClick }) => {
  return (
    <Stack direction="horizontal" gap={2} className="chart-content-bg">
      <Label text={"INSPECTION DETAILS"} className={"flex-fill"} />
      <IconButton
        title={"Open inspection details"}
        iconClass={"ri-arrow-right-s-line"}
        size={"md"}
        onClick={onInspectionDetailsClick}
      />
    </Stack>
  );
};

export default InspectionDetailsBar;
